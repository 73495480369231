<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },
    cardStyles() {
      if (this.$voix.breakpoint.value === 'mobile' || this.$voix.breakpoint.value === 'tablet') {
        return {
          'max-width': '300px',
        }
      }
      return {}
    },
  },
  mounted() {
    defineSlice({

      name: { label: 'One Third Image Card', group: 'Glue', layouts: ['*'] },
      preview: 'OneThirdImageCard.jpg',
      description: 'Empty container with child slices',
      fields: {
        image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 360, height: 225 } } },
        imageOverlayText: { type: 'wysiwyg', label: 'Image Overlay Text', enabled: false },
        title: { type: 'text', label: 'Title' },
        copy: { type: 'wysiwyg', label: 'Description' },
        textColor: { type: 'color', label: 'Text Color' },
        textAlign: { type: 'select', label: 'Text Alignment', options: { 'text-left': 'Left', 'text-center': 'Center', 'text-right': 'Right' } },
        link: { type: 'link', label: 'Link', group: 'Link' },
        newLinkStyle: {
          type: 'select',
          label: 'Link Style',
          group: 'Link',
          options: {
            'light-blue-link': 'Light Blue Link',
            'dark-blue-link': 'Dark Blue Link',
            'dark-blue-button': 'Dark Blue Button',
            'ghost-button': 'Ghost Button',
            'summer-pink': 'Summer Pink',
          },
        },
        linkAlign: { type: 'select', label: 'Link Alignment', group: 'Link', options: { 'text-left': 'Left', 'text-center': 'Center', 'text-right': 'Right' } },
        showLink: { type: 'checkbox', label: 'Show Link', group: 'Link', default: true },
      },
      templates: [{
        label: 'One Third Image Card',
        fields: {
          copy: { value: '<p>The flagship seafood experience at Atlantis is not to be missed by any aficionados of creative cuisine. A great place for special occasions, but you\u2019ll certainly want to come back a second time because the large menu is full of temptations.<\/p>' },
          image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/glue\/dining\/interiors\/fish\/atlantis-fine-dining-fish-restaurant-interior-open-kitchen.jpg' },
          link: { value: { text: 'Learn More', target: '_self', relationship: null, href: '\/new-page' } },
          linkAlign: { value: 'text-left' },
          newLinkStyle: { value: 'light-blue-link' },
          textAlign: { value: 'text-left' },
          title: { value: 'Fish by Jos\u00E9 Andr\u00E9s' },
          textColor: { value: '#000000' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="one-third-image-card bg-white text-gray-700 flex flex-col" :style="cardStyles">
    <div class="relative w-full">
      <VoixMedia :field="fields.image" class="w-full" />
      <div v-if="fields.imageOverlayText.enabled" class="absolute bottom-0 left-0 w-1/2 mb-8 py-2 px-3 bg-glueblue-600 text-white font-sans2 text-xs">
        <div class="w-5/6" v-html="fields.imageOverlayText.value" />
      </div>
    </div>
    <div class="px-8 py-6 flex flex-col justify-between flex-1" :class="[fields.textAlign.value]">
      <div>
        <div
          class="uppercase font-sans font-light leading-tight text-gray-800 text-2xl tracking-wider mb-4"
          :style="{ color: fields.textColor.value }"
          v-html="fields.title.value"
        />
        <div
          class="font-sans2 text-15 font-light text-gray-500"
          :style="{ color: fields.textColor.value }"
          v-html="fields.copy.value"
        />
      </div>
      <div
        v-show="fields.showLink.value"
        class="mt-8 flex-1 flex items-end" :class="{
          'justify-end': fields.linkAlign.value === 'text-right',
          'justify-start': fields.linkAlign.value === 'text-left',
          'justify-center': fields.linkAlign.value === 'text-center',
        }"
      >
        <VoixLink
          :field="fields.link"
          class=" font-sans pb-1"
          :class="[
            {
              'uppercase text-xs special-learnmore border-b border-glueblue-400 hover-draw-border text-glueblue-400': fields.newLinkStyle.value === 'light-blue-link',
              'uppercase text-right text-sm float-right text-glueblue-600': fields.newLinkStyle.value === 'dark-blue-link',
              'uppercase text-xs glue-btn bg-glueblue-600 text-white': fields.newLinkStyle.value === 'dark-blue-button',
              'uppercase glue-btn btn-ghost border-zinc-500 text-zinc-600 uppercase body-11 tracking-widest py-3 px-6 font-bold w-full': fields.newLinkStyle.value === 'ghost-button',
              'text-2xl font-serif italic text-summer2024-600 underline underline-offset-4 tracking-wide': fields.newLinkStyle.value === 'summer-pink',
            }]"
        >
          {{ fields.link.value.text }}
        </VoixLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
html[data-theme="summer-2024-theme"],
.summer-2024-theme {

  .one-third-image-card {
    @apply bg-summer2024-300;

  }

}
</style>
