<script>
import dayjs from 'dayjs'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    noChrome: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      events: [],
    }
  },
  computed: {

    allEventCategories() {
      // Aggregate all the categories from all the events in the array
      const categories = {}

      for (const eventDay of Object.entries(this.events)) {
        for (const event of eventDay[1]) {
          for (const category of event.categories) {
            if (category.name !== 'Daily Events' && category.name !== 'Atlantis Events')
              categories[category.name] = category
          }
        }
      }
      return categories
    },

    eventDaysWithEvents() {
      // Filter all the days to just those with events
      const eventDays = {}
      for (const eventDay of Object.entries(this.events)) {
        if (eventDay[1].length > 0)
          eventDays[eventDay[0]] = eventDay[1]
      }
      return eventDays
    },

    todaysRainyDayEvents() {
      const todaysEvents = this.events[dayjs().format('YYYY-MM-DD')]
      if (todaysEvents)
        return this.getEventsInCategoryForDay(86, todaysEvents)

      return []
    },

  },
  mounted() {
    defineSlice({
      description: 'Daily Events Index',
      fields: {
        showRainyDay: { type: 'checkbox', label: 'It"s raining!' },
        subtitle: { type: 'text', label: 'Subtitle' },
        title: { type: 'text', label: 'Title' },
        intro: { type: 'wysiwyg', label: 'Intro' },
        hideAsside: { type: 'checkbox', label: 'Hide Asside', group: 'Intro Asside' },
        introAssideTitle: { type: 'text', label: 'Intro Asside Title', group: 'Intro Asside' },
        introAsside: { type: 'wysiwyg', label: 'Intro Asside', group: 'Intro Asside' },
        introAssideLink: { type: 'text', label: 'Intro Asside Link', group: 'Intro Asside' },
        introAssideImage: {
          type: 'media',
          label: 'Intro Asside Image',
          group: 'Intro Asside',
          breakpoints: {
            default: {
              width: 200,
            },
          },
        },
      },
      name: { label: 'Daily Events', group: 'Glue' },
      templates: [{
        label: 'Daily Events',
        fields: {
          showRainyDay: { value: true },
          subtitle: { value: 'Activities Highlights' },
          intro: { value: '<h1>Something big <br>is always happening<\/h1><p><br>Atlantis offers an incredible range of amazing events ranging from concerts by world-renowned artists to top-flight sporting events, all against the backdrop of Paradise.<\/p>' },
          introAssideTitle: { value: 'the ATLANTIS MOBILE app' },
          introAsside: { value: '<p><br>Easily plan your stay and navigate the resort with an interactive, GPS-enabled map showing nearby dining options and attractions. Book your activities and stay up to date with all the events happening while in Paradise.<\/p>' },
          introAssideLink: { value: 'Learn More' },
          hideAsside: { value: true },
        },
      }],
    })

    this.getDailyEvents()
  },
  methods: {

    async getDailyEvents() {
      const { data } = await $voixFetch(`/api/devise/daily-events`)
      this.events = data
    },

    areEventsInCategoryForDay(categoryId, eventDay) {
      for (const event of eventDay) {
        if (event.categories.find(category => category.id == categoryId))
          return true
      }
      return false
    },

    getEventsInCategoryForDay(categoryId, eventDay) {
      const events = []
      for (const event of eventDay) {
        if (event.categories.find(category => category.id == categoryId))
          events.push(event)
      }
      return events
    },
  },
}
</script>

<template>
  <div class="container mx-auto font-sans2">
    <div class="grid grid-cols-12 gap-8 px-8 lg:px-0">
      <div class="col-span-12 xl:col-span-8">
        <div class="font-serif text-red-500 italic text-2xl tracking-wider" v-html="fields.subtitle.value" />
        <VoixWysiwyg class="uppercase font-light text-3xl" :field="fields.title" />
        <div class="font-light" v-html="fields.intro.value" />
      </div>
      <div v-if="!noChrome && !fields.hideAsside.value" class="flex col-span-12 xl:col-span-4 bg-white py-2 px-4 items-center space-x-4">
        <div>
          <div class="font-bold text-slate-800 uppercase">
            {{ fields.introAssideTitle.value }}
          </div>
          <div class="text-sm font-light" v-html="fields.introAsside.value" />
          <div>
            <a :href="fields.introAssideLink.href" class="text-glueblue-400 underline uppercase text-xs font-bold">{{
              fields.introAssideLink.value }}</a>
          </div>
        </div>
        <div class="w-[102] flex-none">
          <VoixMedia v-if="fields.introAssideImage.value" :field="fields.introAssideImage" class="w-[102]" />
          <img v-else src="/imgs/events/mobile-app-mini-phone.png" alt="Atlantis Mobile App" width="102" class="w-[102]">
        </div>
      </div>
    </div>

    <div class="space-y-12 pt-12">
      <!-- {{ --RainyDayActivities-- }} -->
      <div v-if="fields.showRainyDay.value && todaysRainyDayEvents && todaysRainyDayEvents.length > 0" class="bg-white p-8 lg:p-16">
        <img src="/imgs/events/rainy-day.png" alt="Rainy day storm clouds and rain" class="float-right ml-4 mb-4">
        <div class="uppercase font-light text-3xl">
          Rainy Day Activities
        </div>
        <div v-for="event in todaysRainyDayEvents" :key="event.id" class="space-y-6 pt-8">
          <div>
            <div class="flex flex-col lg:flex-row lg:space-x-4 lg:divide-x-2 lg:divide-slate-300 uppercase text-sm font-bold">
              <span class="text-glueblue-400 ">{{ event.title }}</span>
              <span class="text-slate-400 lg:pl-4 ">{{ event.venue }}</span>
              <span class="text-slate-400 lg:pl-4 ">{{ event.age }}</span>
              <div v-if="!event.hide_time || !event.hide_end_time" class="flex flex-row space-x-4 divide-x-2 divide-slate-300">
                <span v-if="!event.hide_time" class="text-slate-400 lg:pl-4">
                  <date-formatter :date="`${eventDate} ${event.start}`" format="h:mm a" />
                </span>
                <span v-if="!event.hide_time && !event.hide_end_time && event.end > event.start" class="text-slate-400 pl-4">
                  <date-formatter :date="`${eventDate} ${event.end}`" format="h:mm a" />
                </span>
              </div>
            </div>
            <div class="font-light prose" v-html="event.content" />
          </div>
        </div>
      </div>
      <!-- Regular Events -->
      <div v-for="(eventDay, eventDate) in eventDaysWithEvents" :key="eventDate" class="bg-white p-8 lg:p-16">
        <div class="text-glueblue-700 text-xl font-bold border-b border-slate-200 pb-6">
          <date-formatter :date="eventDate" format="dddd, MMMM D, YYYY" />
        </div>
        <div class="space-y-12 pt-8">
          <div v-for="(category, categoryName) in allEventCategories" :key="category.id">
            <div
              v-if="areEventsInCategoryForDay(category.id, eventDay)"
              class="font-serif text-red-500 italic text-2xl tracking-wide capitalize"
            >
              {{ categoryName.toLowerCase() }}
            </div>
            <div v-for="event in getEventsInCategoryForDay(category.id, eventDay)" :key="event.id" class="space-y-6 pt-8">
              <div>
                <div class="flex flex-col lg:flex-row lg:space-x-4 lg:divide-x-2 lg:divide-slate-300 uppercase text-sm font-bold">
                  <span class="text-glueblue-400 ">{{ event.title }}</span>
                  <span v-if="event.venue" class="text-slate-400 lg:pl-4 ">{{ event.venue === 'MarinaVillage' ? 'Marina Village' : event.venue }}</span>
                  <span v-if="event.age" class="text-slate-400 lg:pl-4 ">{{ event.age }}</span>
                  <div v-if="!event.hide_time || !event.hide_end_time" class="flex flex-row space-x-4 divide-x-2 divide-slate-300">
                    <span v-if="!event.hide_time" class="text-slate-500 lg:pl-4"><span class="text-slate-400 font-normal">Start:</span> <date-formatter :date="`${eventDate} ${event.start}`" format="h:mm a" /></span>
                    <span v-if="!event.hide_time && !event.hide_end_time && event.end > event.start && !event.hide_end_time" class="text-slate-500 pl-4"><span class="text-slate-400 0 font-normal">End:</span> <date-formatter :date="`${eventDate} ${event.end}`" format="h:mm a" /></span>
                  </div>
                </div>
                <div class="font-light prose" v-html="event.content" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
